import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

interface IKatoriPage {}

const URLS = [
    "https://res.cloudinary.com/candyman/image/upload/v1687948491/gj/katori-output/000.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948490/gj/katori-output/001.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948487/gj/katori-output/002.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948489/gj/katori-output/003.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948492/gj/katori-output/004.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948493/gj/katori-output/005.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948495/gj/katori-output/006.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948494/gj/katori-output/007.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948512/gj/katori-output/008.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948513/gj/katori-output/009.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948492/gj/katori-output/010.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948493/gj/katori-output/011.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948495/gj/katori-output/012.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948494/gj/katori-output/013.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948490/gj/katori-output/014.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948489/gj/katori-output/015.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948487/gj/katori-output/016.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948488/gj/katori-output/017.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948516/gj/katori-output/018.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948516/gj/katori-output/019.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948512/gj/katori-output/020.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948513/gj/katori-output/021.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948511/gj/katori-output/022.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948510/gj/katori-output/023.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948516/gj/katori-output/024.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948516/gj/katori-output/025.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948518/gj/katori-output/026.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948486/gj/katori-output/027.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948491/gj/katori-output/028.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948490/gj/katori-output/029.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948517/gj/katori-output/030.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948515/gj/katori-output/031.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948518/gj/katori-output/032.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948487/gj/katori-output/033.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948512/gj/katori-output/034.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948513/gj/katori-output/035.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948511/gj/katori-output/036.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948509/gj/katori-output/037.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948492/gj/katori-output/038.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948493/gj/katori-output/039.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948502/gj/katori-output/040.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948503/gj/katori-output/041.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948504/gj/katori-output/042.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948504/gj/katori-output/043.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948509/gj/katori-output/044.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948507/gj/katori-output/045.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948505/gj/katori-output/046.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948506/gj/katori-output/047.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948497/gj/katori-output/048.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948496/gj/katori-output/049.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948508/gj/katori-output/050.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948507/gj/katori-output/051.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948516/gj/katori-output/052.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948506/gj/katori-output/053.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948503/gj/katori-output/054.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948503/gj/katori-output/055.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948505/gj/katori-output/056.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948504/gj/katori-output/057.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948501/gj/katori-output/058.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948501/gj/katori-output/059.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948497/gj/katori-output/060.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948496/gj/katori-output/061.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948497/gj/katori-output/062.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948498/gj/katori-output/063.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948500/gj/katori-output/064.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948501/gj/katori-output/065.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948499/gj/katori-output/066.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948500/gj/katori-output/067.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948502/gj/katori-output/068.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948503/gj/katori-output/069.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948500/gj/katori-output/070.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948501/gj/katori-output/071.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948499/gj/katori-output/072.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948498/gj/katori-output/073.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948496/gj/katori-output/074.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948496/gj/katori-output/075.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948497/gj/katori-output/076.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948498/gj/katori-output/077.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948508/gj/katori-output/078.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948508/gj/katori-output/079.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948505/gj/katori-output/080.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948504/gj/katori-output/081.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948503/gj/katori-output/082.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948502/gj/katori-output/083.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948506/gj/katori-output/084.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948506/gj/katori-output/085.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948507/gj/katori-output/086.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948508/gj/katori-output/087.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948498/gj/katori-output/088.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948497/gj/katori-output/089.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948506/gj/katori-output/090.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948505/gj/katori-output/091.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948508/gj/katori-output/092.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948508/gj/katori-output/093.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948504/gj/katori-output/094.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948504/gj/katori-output/095.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948502/gj/katori-output/096.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948502/gj/katori-output/097.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948499/gj/katori-output/098.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948499/gj/katori-output/099.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948496/gj/katori-output/100.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948496/gj/katori-output/101.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948497/gj/katori-output/102.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948498/gj/katori-output/103.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948500/gj/katori-output/104.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948501/gj/katori-output/105.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948500/gj/katori-output/106.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948498/gj/katori-output/107.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948502/gj/katori-output/108.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948503/gj/katori-output/109.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948500/gj/katori-output/110.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948502/gj/katori-output/111.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948500/gj/katori-output/112.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948499/gj/katori-output/113.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948497/gj/katori-output/114.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948496/gj/katori-output/115.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948497/gj/katori-output/116.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948498/gj/katori-output/117.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948509/gj/katori-output/118.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948507/gj/katori-output/119.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948501/gj/katori-output/120.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948503/gj/katori-output/121.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948505/gj/katori-output/122.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948504/gj/katori-output/123.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948509/gj/katori-output/124.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948507/gj/katori-output/125.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948506/gj/katori-output/126.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948507/gj/katori-output/127.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948497/gj/katori-output/128.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948496/gj/katori-output/129.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948509/gj/katori-output/130.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948507/gj/katori-output/131.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948505/gj/katori-output/132.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948507/gj/katori-output/133.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948502/gj/katori-output/134.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948503/gj/katori-output/135.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948505/gj/katori-output/136.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948504/gj/katori-output/137.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948500/gj/katori-output/138.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948501/gj/katori-output/139.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948512/gj/katori-output/140.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948514/gj/katori-output/141.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948512/gj/katori-output/142.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948509/gj/katori-output/143.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948517/gj/katori-output/144.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948514/gj/katori-output/145.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948517/gj/katori-output/146.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948488/gj/katori-output/147.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948491/gj/katori-output/148.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948489/gj/katori-output/149.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948517/gj/katori-output/150.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948514/gj/katori-output/151.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948517/gj/katori-output/152.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948487/gj/katori-output/153.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948512/gj/katori-output/154.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948514/gj/katori-output/155.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948512/gj/katori-output/156.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948509/gj/katori-output/157.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948492/gj/katori-output/158.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948493/gj/katori-output/159.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948491/gj/katori-output/160.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948490/gj/katori-output/161.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948488/gj/katori-output/162.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948489/gj/katori-output/163.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948492/gj/katori-output/164.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948493/gj/katori-output/165.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948495/gj/katori-output/166.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948494/gj/katori-output/167.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948511/gj/katori-output/168.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948514/gj/katori-output/169.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948492/gj/katori-output/170.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948493/gj/katori-output/171.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948495/gj/katori-output/172.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948494/gj/katori-output/173.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948491/gj/katori-output/174.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948489/gj/katori-output/175.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948486/gj/katori-output/176.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948489/gj/katori-output/177.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948517/gj/katori-output/178.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948515/gj/katori-output/179.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948509/gj/katori-output/180.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948511/gj/katori-output/181.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948514/gj/katori-output/182.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948513/gj/katori-output/183.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948487/gj/katori-output/184.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948518/gj/katori-output/185.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948515/gj/katori-output/186.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948518/gj/katori-output/187.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948489/gj/katori-output/188.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948487/gj/katori-output/189.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948487/gj/katori-output/190.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948518/gj/katori-output/191.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948515/gj/katori-output/192.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948516/gj/katori-output/193.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948510/gj/katori-output/194.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948512/gj/katori-output/195.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948514/gj/katori-output/196.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948513/gj/katori-output/197.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948494/gj/katori-output/198.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948495/gj/katori-output/199.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948488/gj/katori-output/200.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948488/gj/katori-output/201.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948490/gj/katori-output/202.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948490/gj/katori-output/203.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948495/gj/katori-output/204.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948494/gj/katori-output/205.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948492/gj/katori-output/206.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948492/gj/katori-output/207.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948511/gj/katori-output/208.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948510/gj/katori-output/209.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948495/gj/katori-output/210.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948494/gj/katori-output/211.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948492/gj/katori-output/212.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948492/gj/katori-output/213.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948488/gj/katori-output/214.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948489/gj/katori-output/215.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948490/gj/katori-output/216.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948490/gj/katori-output/217.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948486/gj/katori-output/218.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948486/gj/katori-output/219.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948510/gj/katori-output/220.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948510/gj/katori-output/221.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948513/gj/katori-output/222.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948513/gj/katori-output/223.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948486/gj/katori-output/224.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948486/gj/katori-output/225.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948516/gj/katori-output/226.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948515/gj/katori-output/227.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948488/gj/katori-output/228.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948488/gj/katori-output/229.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948486/gj/katori-output/230.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948486/gj/katori-output/231.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948516/gj/katori-output/232.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948515/gj/katori-output/233.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948510/gj/katori-output/234.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948510/gj/katori-output/235.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948512/gj/katori-output/236.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948513/gj/katori-output/237.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948495/gj/katori-output/238.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948494/gj/katori-output/239.png",
    "https://res.cloudinary.com/candyman/image/upload/v1687948504/gj/katori-output/240.png"
];

interface ICanvasImageSequence {
    imageWidth: number;
    imageHeight: number;
    enabled?: boolean;
}

const drawFrame = (
    n: number,
    context: CanvasRenderingContext2D,
    frames: any[],
    canvas: HTMLCanvasElement
) => {
    const currentFrame = Math.round(n);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(frames[currentFrame], 0, 0, canvas.width, canvas.height);
};

import { useCanvasImageSequence } from "../WelcomeSection/useCanvasImageSequence";
import { withPrefix } from "gatsby";
import { pxToRem } from "../../utils/px-to-rem";

export const KatoriPage = ({
    imageWidth,
    imageHeight
}: ICanvasImageSequence) => {
    const mainRef = useRef(null);

    const tl = useRef<GSAPTimeline>(null);

    const { loaded, canvasScale, frames, scaledBy } = useCanvasImageSequence({
        imageUrls: URLS,
        imageHeight,
        imageWidth,
        mainRef
    });

    useLayoutEffect(() => {
        //run once

        const ctx = gsap.context(() => {
            const canvas =
                gsap.utils.toArray<HTMLCanvasElement>(".image-canvas")[0];
            // const repositionCanvas = () => {
            //     const box = canvas.getBoundingClientRect();
            //     console.log(box);
            //
            //     gsap.to(canvas, {
            //         // x: -left / 2,
            //         // y: -top,
            //         duration: 0
            //     });
            // };
            //
            // repositionCanvas();
            if (loaded) {
                // canvas.style.left = "0px";
                // canvas.style.top = "0";
                // canvas.style.position = "absolute";

                // window.addEventListener("resize", repositionCanvas, false);

                const frameNumberTweener = {
                    value: 1
                };

                const context = canvas.getContext("2d");

                // Hack: the first frame wasn't showing before the main scrolltrigger got activated, so doing this
                
                drawFrame(0, context, frames, canvas);
                   

                tl.current = gsap
                    .timeline({
                        defaults: {
                            ease: "none",
                            repeat: -1,
                            yoyo: true
                        }
                    })

                    .to(frameNumberTweener, {
                        duration: 20,
                        value: URLS.length - 1,
                        onUpdate: () => {
                            drawFrame(
                                frameNumberTweener.value,
                                context,
                                frames,
                                canvas
                            );
                        }
                    });

                gsap.to(".gj-main-bg", {
                    rotation: "-=360",
                    ease: "none",
                    repeat: -1,
                    repeatDelay: 0,
                    duration: 300
                });
            }
        }, mainRef);

        return () => ctx.revert();
    }, [loaded]);

    // let mainTl = null;
    // useLayoutEffect(() => {
    //     const ctx = gsap.context(() => {
    //         mainTl = gsap.timeline({});
    //         const video = gsap.utils.toArray<HTMLVideoElement>("video")[0];
    //         video.addEventListener("loadeddata", () => {
    //             // video.play();
    //             // video.pause();
    //             gsap.timeline({
    //                 repeat: -1,
    //                 yoyo: true
    //             }).to(video, {
    //                 duration: video.duration,
    //                 currentTime: video.duration,
    //                 ease: "none"
    //             });
    //         });
    //     }, mainRef);

    //     return () => ctx.revert();
    // }, []);

    return (
        <div
            ref={mainRef}
            css={{
                position: "fixed",
                width: "100vw",
                left: 0,
                top: 0,
                height: "100vh",
                overflow: "hidden",
                background: "black",
                ".gj-main-bg-container": {
                    zIndex: 0,
                    position: "absolute",
                    top: "-100vh",
                    left: "-100vw",
                    right: "0",
                    bottom: "0",
                    width: "300vw",
                    height: "300vh",
                    ".gj-main-bg": {
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "repeat",
                        backgroundSize: "160vw 160vh"
                    }
                }
            }}
        >
            <div
                className="main-container"
                css={{
                    position: "fixed",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100% !important",
                    height: "100% !important",
                    overflow: "hidden"
                }}
            >
                <div className="gj-main-bg-container">
                    <div 
                        style={{
                            backgroundImage: `url(${withPrefix(
                                "/images/main-bg.png"
                            )})`
                        }}
                        className="gj-main-bg"
                    />
                </div>
                <div
                    className="main-container"
                    css={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100% !important",
                        height: "100% !important",
                        overflow: "hidden"
                    }}
                >
                    <div
                        css={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            left: "0px",
                            bottom: "0px",
                            willChange: "transform",
                            transform: `scale(${canvasScale})`,
                            zIndex: 200,
                            transformOrigin: "left top"
                        }}
                    >
                        <canvas
                            className="image-canvas"
                            css={{
                                // position: "absolute",
                                marginTop:
                                    scaledBy === "width"
                                        ? `calc((${imageHeight}px * ${canvasScale} - 100vh) / -4)`
                                        : "0px",
                                marginLeft:
                                    scaledBy === "height"
                                        ? `calc((${imageWidth * canvasScale}px - 100vw) / -2 + ${pxToRem(20)})`
                                        : "0px",

                                // width: "100%",
                                // height: "100%",
                                // position: "absolute",
                                // top: "0px",
                                // right: "0px",
                                // left: "0px",
                                // bottom: "0px",
                                willChange: "transform"
                                // opacity: 0
                                // transform: `scale(${canvasScale})`,
                                // transformOrigin: "center center"
                            }}
                            width={imageWidth}
                            height={imageHeight}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
